import React from "react"
import { LocalImage } from "./local-image.component"
import { Image } from "./image.component"

class Video extends React.Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
    this.state = {
      status: "stop",
    }
  }

  playVideo() {
    const video = this.videoRef.current
    if (video) {
      video.play()
      video.controls = true
      video.onplay = () => {
        this.setState({ status: "play" })
      }
      video.onpause = () => {
        this.setState({ status: "stop" })
      }
    }
  }

  render() {
    const { src, thumbnail } = this.props
    const { status } = this.state
    return (
      <div className="cover-video">
        <Image className="w-100" image={thumbnail} />
        <video width="100%" ref={this.videoRef} src={src} />
        <div
          className={"play-btn " + (status === "play" ? "d-none" : "")}
          onClick={() => this.playVideo()}
        >
          <LocalImage alt="video" src="Play.png" />
        </div>
      </div>
    )
  }
}

export default Video
