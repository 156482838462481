import React from "react"
import LayoutComponent from "../components/layout.component"
import HeaderComponent from "../components/header.component"
import Video from "../components/common/video"
import { graphql } from "gatsby"
import { Slices } from "../components/slices.component"
import { RichText } from "../components/common/rich-text.component"
import { CustomLink } from "../components/common/custom-link.component"
import { getLinkClass } from "../utils/styles"
import { linkFragment } from "../link-resolver"
import SEO from "../components/common/seo.component"

const IndexPage = ({ data }) => {
  const page = data.prismic.allHomePages.edges[0]
  const {
    video_file_thumbnail,
    video_link,
    background_image,
    background_color,
    buttons,
    content,
    body,
  } = page.node
  return (
    <LayoutComponent>
      <SEO
        title={page.node.page_title || page.node.title}
        description={page.node.description || page.node.text}
        keywords={page.node.keywords}
        image={page.node.preview_image}
        lang={page.node._meta.lang}
      />
      <section className="home-top-block text-white">
        <div className="home-top-background pb-5">
          <div className="container">
            <HeaderComponent/>
            <div className="text-center home-page-text mt-100">
              <RichText render={content}/>
              {buttons.map(button => {
                return (
                  <CustomLink
                    link={button.link}
                    className={getLinkClass(button.link_style, "link")}
                  >
                    {button.link_text}
                  </CustomLink>
                )
              })}
            </div>
            <div className="container home-page-video-container">
              <Video src={video_link.url} thumbnail={video_file_thumbnail}/>
            </div>
          </div>
        </div>
      </section>
      <Slices body={body}/>
    </LayoutComponent>
  )
}

IndexPage.fragments = [linkFragment]

export default IndexPage

export const homePage = graphql`
    query HomePageQuery {
        prismic {
            allHomePages {
                edges {
                    node {
                        page_title
                        description
                        keywords {
                            keyword
                        }
                        preview_image
                        _meta {
                            uid
                            lang
                        }
                        body {
                            ... on PRISMIC_HomePageBodyGallery {
                                type
                                label
                                primary {
                                    anchor
                                    bgColor
                                    bgImage
                                    text
                                    title
                                }
                                fields {
                                    image
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_HomePageBodyPricing_plans {
                                type
                                label
                                fields {
                                    price_units
                                    plan_price
                                    plan_name
                                    plan_features
                                    link_text
                                    link_style
                                    link {
                                        ...link
                                    }
                                    is_free_plan
                                }
                                primary {
                                    bg_image
                                    bg_color
                                    title
                                    text
                                }
                            }
                            ... on PRISMIC_HomePageBodyItems_collection {
                                label
                                type
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                    title
                                    link_style
                                    link_text
                                }
                                fields {
                                    tag
                                }
                            }
                            ... on PRISMIC_HomePageBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    text_content
                                    title
                                    form_url
                                    form_script
                                }
                            }
                            ... on PRISMIC_HomePageBodyText {
                                type
                                label
                                primary {
                                    bg_color
                                    text
                                    bg_image
                                }
                            }
                            ... on PRISMIC_HomePageBodyBlock_with_text_and_image {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    min_height
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_HomePageBodyFeature {
                                type
                                label
                                primary {
                                    bd_color
                                    bg_image
                                    text
                                }
                                fields {
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    text
                                    title
                                    link_text
                                }
                            }
                        }
                        buttons {
                            link {
                                ...link
                            }
                            link_style
                            link_text
                        }
                        content
                        video_file_thumbnail
                        video_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        background_image
                        background_color
                    }
                }
            }
        }
    }
`
